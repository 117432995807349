<template>
    <div class="test">
        <div class="fullScreen">

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },

    mounted() {
        
    },

    methods: {

    },
}
</script>

<style scoped lang="scss">

.test {
    font-family: "Poppins Light";
    margin: 0 auto;
    margin-top: 10vh;
    text-align: center;
}

.fullScreen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>